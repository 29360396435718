import { Link } from "gatsby";
import React, { useContext } from "react";
import Layout from "../components/layout";
import CartItemSummary from "../components/partials/cart-item-summary";
import RecommendedProductsCta from "../components/partials/recommended-products-cta";
import Seo from "../components/seo";
import CartContext from "../context/cart-context";
import shippingPhoto from "../images/delivery-install.png";
import { LinerStyles } from "../components/partials/liner-styles";
import { RecommendedCovers } from "../components/partials/recommend-covers";

function AddAccessoriesToYourCart() {
  const cartContext = useContext(CartContext);

  const cartHasCategory = (category) => {
    return cartContext.contents.some((item) =>
      item.category.includes(category)
    );
  };

  return (
    <Layout>
      <Seo title="Window Well Cover Accessories" />
      <div className="container px-4 mx-auto my-8">
        <div>
          <h1>Window Well Covers Added to Cart</h1>
          <p>Be sure to check out our accessories to build out your covers!</p>
        </div>
        <RecommendedProductsCta />
        {cartHasCategory("covers") && !cartHasCategory("liners") && (
          <LinerStyles header="Bundle your window well cover with a window well liner" />
        )}
        {!cartHasCategory("covers") && cartHasCategory("liners") && (
          <RecommendedCovers />
        )}
        <div className="my-4 md:mx-4">
          <h2>Your Cart</h2>
          <div className="flex flex-wrap mt-2 md:mx-8">
            <div className="md:p-4 w-full">
              {cartContext.contents.map((value, index) => {
                return (
                  <CartItemSummary
                    key={index}
                    id={value.id}
                    url={value.url}
                    image={value.image}
                    price={value.salePrice ? value.salePrice : value.basePrice}
                    title={value.title}
                    quantity={value.quantity}
                    attributes={value.attributes ? value.attributes : []}
                    size={value.size}
                    style={value.style}
                  />
                );
              })}
              {!cartContext.contents.length && (
                <div className={`overflow-y-scroll p-8`}>
                  <h3>Your cart is empty</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end py-4 md:px-0 md:mr-4 uppercase">
          <Link
            className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded`}
            to="/checkout/"
          >
            Go To Checkout
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default AddAccessoriesToYourCart;
